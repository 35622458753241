import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '../../app-settings/config/config.service';
import { KeyService } from '../../api-keys/key.service';
import { SolutionService } from '../../services/solution.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-link',
    templateUrl: './app-link.component.html',
    styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit, OnDestroy {

    public apiKey: string;

    constructor(
        private configService: ConfigService,
        private keyService: KeyService,
        private solutionService: SolutionService,
        private userService: UserService
    ) { }

    private isOwner: boolean = false;
    private subscriptions = new Subscription();

    /** NgOnInit. */
    ngOnInit(): void {
        /*
         * The rules for creating the app link:
         *
         * If the current Solution has an alias (config.appId), always present a link
         * to map.mapsindoors.com with the alias as apiKey parameter.
         *
         * If the current logged in user is an owner, and there is an API key for the current Solution,
         * present a link to map.mapsindoors.com with the first API key as apiKey parameter.
         */

        this.subscriptions
            .add(
                this.userService.getUser()
                    .subscribe(user => this.isOwner = user.roles.includes('owner'))
            );

        this.subscriptions
            .add(
                this.solutionService.selectedSolution$
                    .pipe(
                        switchMap(() => this.configService.getConfig()),
                        tap(config => {
                            this.apiKey = null;
                            if (config.appId && (config.appSettings?.mapboxAccessToken || config.appSettings?.gmKey)) {
                                this.apiKey = config.appId;
                            }
                        }),
                        filter(() => !this.apiKey && this.isOwner),
                        switchMap(() => this.keyService.getKeys().pipe(tap(keys => this.apiKey = keys.length ? keys[0].id : null)))
                    )
                    .subscribe()
            );
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
