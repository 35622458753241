import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { FilterNonUserRolePipe, FilterUserRolePipe, FilterUsersBySolutionPipe, FilterUsersPipe } from './pipes/filter-users';
import { FilterSolutionsPipe, FilterVenuesPipe } from './pipes/filter-solutions';
import {
    GoogleApiModule,
    NG_GAPI_CONFIG,
    NgGapiClientConfig
} from 'ng-gapi';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MapAdapterModule } from './adapter/map-adapter.module';
import { AdminGuard } from './guards/admin-guard.service';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { AnalyticsDashboardModule } from './analytics-dashboard/analytics-dashboard.module';
import { ApiProvider } from './services/api-provider.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { AuthService } from './auth/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BuildingsModule } from './buildings/buildings.module';
import { CategoriesComponent } from './categories/categories.component';
import { CustomerLevelModulesGuard } from './guards/customer-level-modules.guard';
import { CustomersModule } from './customers/customers.module';
import { DataIssueModule } from './data-issue/data-issue.module';
import { DeploymentConfigModule } from './deployment-config/deployment-config.module';
import { DeploymentVenueModule } from './deployment-venue/deployment-venue.module';
import { ExportComponent } from './export.component';
import { FallbackComponent } from './fallback/fallback.component';
import { FilterAppUserRolesByNamePipe } from './pipes/filter-app-user-roles';
import { FilterBuildingsPipe } from './pipes/filter-buildings';
import { FilterTypesPipe } from './pipes/filter-types';
import { FormatService } from './services/format.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { HeaderComponent } from './header/header.component';
import { HttpErrorInterceptorService } from './services/http-error-interceptor.service';
import { ImportExportModule } from './import-export/import-export.module';
import { KeyService } from './api-keys/key.service';
import { LanguageService } from './services/language.service';
import { LocationService } from './locations/location.service';
import { LocationTemplatesComponent } from './location-types/location-templates/location-templates.component';
import { LogComponent } from './log.component';
import { LogService } from './services/log.service';
import { MapModule } from './map/map.module';
import { MediaLibraryModule } from './media-library/media-library.module';
import { NewVenueModule } from './new-venue/new-venue.module';
import { NewsComponent } from './news/news.component';
import { OwnerGuard } from './guards/owner-guard.service';
import { SharedModule } from './shared/shared.module';
import { StreetViewModalComponent } from './subcomponents/street-view/street-view-modal/street-view-modal.component';
import { UserService } from './services/user.service';
import { UsersComponent } from './users.component';
import { authConfig } from './auth/auth-config';
import { authModuleConfig } from './auth/auth-module-config';
import { SolutionSettingModule } from './solution-settings/solution-settings-module';
import { OccupantsGuard } from './guards/occupants.guard';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { AppLinkComponent } from './header/app-link/app-link.component';

const gapiClientConfig: NgGapiClientConfig = {
    client_id: '789475087058-2n0k3qh4qb0obnojcdj4ldrqm7l8lgph.apps.googleusercontent.com',
    discoveryDocs: []
};

export function apiProviderFactory(provider: ApiProvider) {
    return () => provider.checkFastestApi();
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

if (environment.sendLogsToSentry) {
    Sentry.init({
        dsn: 'https://0a4ee5a3ac9d43d3b93a0fa9460d8b16@sentry.io/4156833',
        release: `mi-cms@${environment.version}`,
        environment: environment.name,
    });
}

@NgModule({
    declarations: [
        AppComponent,
        CategoriesComponent,
        ExportComponent,
        UsersComponent,
        LogComponent,
        AlertDialogComponent,
        LocationTemplatesComponent,
        StreetViewModalComponent,
        HeaderComponent,
        NewsComponent,
        FilterAppUserRolesByNamePipe,
        FilterTypesPipe,
        FilterBuildingsPipe,
        FilterUsersPipe,
        FilterUsersBySolutionPipe,
        FilterUserRolePipe,
        FilterNonUserRolePipe,
        FilterSolutionsPipe,
        FilterVenuesPipe,
        FallbackComponent,
        AppLinkComponent
    ],
    imports: [
        OAuthModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        AppSettingsModule,
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig
        }),
        BuildingsModule,
        AnalyticsDashboardModule,
        DeploymentVenueModule,
        CustomersModule,
        DataIssueModule,
        DeploymentConfigModule,
        ImportExportModule,
        NewVenueModule,
        AppRoutingModule,
        MapModule,
        SolutionSettingModule,
        MediaLibraryModule,
        MapAdapterModule
    ],
    providers: [
        ApiProvider,
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
        UserService,
        FormatService,
        LocationService,
        LanguageService,
        KeyService,
        LogService,
        AdminGuard,
        OwnerGuard,
        CustomerLevelModulesGuard,
        OccupantsGuard
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
    constructor(private authService: AuthService, private apiProvider: ApiProvider) { }

    ngDoBootstrap(app: ApplicationRef): void {
        this.apiProvider.checkFastestApi()
            .then(() => this.authService.runInitialLoginSequence())
            .then(() => app.bootstrap(AppComponent))
            .catch(error => {
                throw Error(`Error occured while checking fastest API or running initial login sequence: ${JSON.stringify(error)}`);
            });
    }

}
